import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { device } from '../styles/theme-constants';

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        height: '100%',
        backgroundColor: '#f5f5f5',
        minHeight: '100vh'
    },
    container: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'rgb(23, 43, 77)'
    },
    image: {
        paddingTop: '1rem'
    },
    link: {
        color: '#1976d2'
    },
    text: {
        fontSize: '1rem !important'
    }
});

const Image = styled.img`
    @media ${device.tablet} { 
        width: 400px;
        height: 200px;
    }
    @media ${device.mobileL} { 
        width: 300px;
        height: 150px;
    }
    @media ${device.mobileS} { 
        width: 200px;
        height: 100px;
    }
`;

const ContainerText = styled.div`
    padding-top: 2.5rem;
    width: 100%;
    max-width: 50%;
    text-align: center;
    @media ${device.laptop} { 
        max-width: 100%;
    }
`;

export default function Custom404() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12}>
                    <div className={classes.container}>
                        <ContainerText>
                            <Typography variant="h4" gutterBottom>
                                Error 404
                            </Typography>
                        </ContainerText>
                    </div>
                </Grid>
                <Grid item xs={12} className={classes.image}>
                    <div className={classes.container}>
                        <Image src={`${process.env.CDN_HOST}/error404_2.png`} alt="Error" width="600" height="380" />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.container}>
                        <ContainerText>
                            <Typography className={classes.text} variant="body2" gutterBottom>
                                ¡Ups! ¡Te topaste con una página fantasma!
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                Regresar a
                                <Link className={classes.link} href="/">
                                    {` inicio `}
                                </Link>
                            </Typography>
                        </ContainerText>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}